import React from "react";
const Typography = (props) => {
  const width = window.screen.availWidth - 40;
  return (
    <p
      style={
        width <= 1000
          ? {
              ...props.responsive,
              display: "inline-block",
              marginTop: props.marginTop ?? 0,
              marginBottom: 0,
            }
          : {
              ...props,
              display: "inline-block",
              marginTop: 0,
              marginBottom: 0,
            }
      }
    >
      {props.children}
    </p>
  );
};

export default React.memo(Typography);
