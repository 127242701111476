import React from "react";
import "./Footer.css";
import Typography from "../Typography";
import LeftCurlImage from "../../../images/left-curl-down.png";
import RightCurlImage from "../../../images/right-curl-down.png";
import { text } from "../coreComponentsConfig";
import Logo from "../Navbar/Logo/Logo";
import { HashLink as Link } from "react-router-hash-link";
import FACEBOOK_SVG from "../../../images/facebook-white.svg";
import INSTAGRAM_SVG from "../../../images/instagram-white.svg";
import YOUTUBE_SVG from "../../../images/youtube-white.svg";

const Footer = () => (
  <div className="footer">
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "46px",
      }}
    >
      <img src={LeftCurlImage} alt="left-curl" style={{ marginTop: "4px" }} />
      <Typography {...text.smallText7} marginLeft={8} marginRight={8}>
        Humbly dedicated to His Divine Grace AC Bhaktivedanta Swami Srila
        Prabhupada
      </Typography>
      <img src={RightCurlImage} alt="right-curl" style={{ marginTop: "4px" }} />
    </div>
    <div>
      <Logo whiteLogo />
      <div className="socialIconsFooter">
        <a href="https://www.facebook.com/people/ISKCON-Gwalior/100069117747248">
          <img src={FACEBOOK_SVG} className="socialIconFooter" alt="fb" />
        </a>
        <a href="https://www.instagram.com/iskcongwalior">
          <img src={INSTAGRAM_SVG} className="socialIconFooter" alt="insta" />
        </a>
        <a href="https://www.youtube.com/@iskcongwalior7023">
          <img src={YOUTUBE_SVG} className="socialIconFooter" alt="yt" />
        </a>
      </div>
    </div>
    <div className="footerHr" />
    <div className="bottomFooter">
      <div className="bottomLeftFooter">
        <Link to="/privacy">
          <Typography {...text.smallText8}>Privacy Policy</Typography>
        </Link>
        <div className="vrFooter"></div>
        <Link to="/tnc">
          <Typography {...text.smallText8}>Terms & Conditions</Typography>
        </Link>
        <div className="vrFooter"></div>
        <Link to="/cancellation-policy">
          <Typography {...text.smallText8}>Cancellation Policy</Typography>
        </Link>
      </div>
      <Typography {...text.verySmallText}>
        ©2023 ISKCON Gwalior. All rights Reserved.
      </Typography>
    </div>
  </div>
);

export default React.memo(Footer);
