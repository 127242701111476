import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { text } from "../../coreComponentsConfig";
import Typography from "../../Typography";

const NavItem = (props) => (
  <Link to={props.href} smooth>
    <Typography {...text.smallText1}>{props.children}</Typography>
  </Link>
);

export default React.memo(NavItem);
