import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";
import Navbar from "./components/core/Navbar/Navbar";
import Footer from "./components/core/Footer/Footer";
import Loader from "./components/core/Loader/Loader";
import NotFound from "./components/core/NotFound/NotFound";
import ScrollToTop from "./ScrollTop";
import { Suspense } from "react";

const Home = React.lazy(() => import("./components/Home/Home"));
const About = React.lazy(() => import("./components/About/About"));
const PrivacyPolicy = React.lazy(() => import("./components/Privacy/Privacy"));
const TermsAndConditions = React.lazy(() => import("./components/Tnc/Tnc"));
const CancellationPolicy = React.lazy(() =>
  import("./components/CancellationPolicy/Cancellation")
);
const Administration = React.lazy(() =>
  import("./components/Administration/Administration")
);
const FounderAcharya = React.lazy(() =>
  import("./components/FounderAcharya/FounderAcharya")
);
const IYF = React.lazy(() => import("./components/IYF/IYF"));
const Donation = React.lazy(() => import("./components/Donation/Donation"));
const Checkout = React.lazy(() => import("./components/Checkout/Checkout"));
const DonationResponse = React.lazy(() =>
  import("./components/DonationResponse/DonationResponse")
);

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="app">
        <div className="main">
          <Navbar />
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route path="/" exact>
                <Home />
              </Route>
              <Route path="/about" exact>
                <About />
              </Route>
              <Route path="/founder" exact>
                <FounderAcharya />
              </Route>
              <Route path="/administration" exact>
                <Administration />
              </Route>
              <Route path="/cancellation-policy" exact>
                <CancellationPolicy />
              </Route>
              <Route path="/privacy" exact>
                <PrivacyPolicy />
              </Route>
              <Route path="/tnc" exact>
                <TermsAndConditions />
              </Route>
              <Route path="/leaders" exact>
                <Administration />
              </Route>
              <Route path="/iyf" exact>
                <IYF />
              </Route>
              <Route path="/donation" exact>
                <Donation />
              </Route>
              {/* <Route path="/checkout" exact>
                <Checkout />
              </Route>
              <Route path="/donation-response" exact>
                <DonationResponse />
              </Route> */}
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </Suspense>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
