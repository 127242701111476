import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import LOGO_MAIN from "../../../../images/logo.svg";
import LOGO_WHITE from "../../../../images/logo-white.svg";

const Logo = (props) => (
  <Link to="/">
    <div>
      <img src={props.whiteLogo ? LOGO_WHITE : LOGO_MAIN} alt="logo" />
    </div>
  </Link>
);

export default React.memo(Logo);
