import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Logo from "./Logo/Logo";
import "./Navbar.css";
import NavItem from "./NavItem/NavItem";

const Navbar = () => {
  const location = useLocation();

  const isTransparent = (path) => {
    if (
      path === "/" ||
      path === "/iyf" ||
      path === "/privacy" ||
      path === "/tnc" ||
      path === "/administration" ||
      path === "/cancellation-policy" ||
      path === "/leaders"
    )
      return true;
    return false;
  };

  const [transparent, setTransparent] = useState(
    isTransparent(location.pathname)
  );

  const [openSideBar, setOpenSideBar] = useState(false);

  useEffect(() => {
    setTransparent(isTransparent(location.pathname));
  }, [location.pathname]);

  /* Set the width of the side navigation to 250px */
  function openNav() {
    setOpenSideBar(true);
  }

  /* Set the width of the side navigation to 0 */
  function closeNav() {
    setOpenSideBar(false);
  }

  return (
    <>
      <div
        className={`navbar-desktop navbar ${
          transparent ? "transparentNav" : ""
        }`}
      >
        <NavItem href="/about">About ISKCON</NavItem>
        <NavItem href="/#schedule">Temple Schedule</NavItem>
        <NavItem href="/#gallery">Gallery</NavItem>
        <NavItem href="/donation">Donate Now</NavItem>
        <Logo whiteLogo={!transparent} />
        <NavItem href="/founder">Founder Acharya</NavItem>
        <NavItem href="/leaders">Our Leaders</NavItem>
        <NavItem href="/iyf">Youth Forum</NavItem>
        <NavItem href="/#contact">Contact Us</NavItem>
      </div>
      <div
        className={`overlay ${openSideBar ? "overlay-visible" : ""}`}
        onClick={closeNav}
      />
      <div
        id="mySidenav"
        className={`${
          openSideBar ? "open-side-bar" : "close-side-bar"
        } sidebar-mobile sidenav ${!transparent ? "sideBarOpaque" : ""}`}
      >
        <span className="closebtn" onClick={closeNav}>
          &times;
        </span>
        <Logo whiteLogo={!transparent} />
        <NavItem href="/about">About ISKCON</NavItem>
        <NavItem href="/#schedule">Temple Schedule</NavItem>
        <NavItem href="/#gallery">Gallery</NavItem>
        <NavItem href="/donation">Donate Now</NavItem>
        <NavItem href="/founder">Founder Acharya</NavItem>
        <NavItem href="/leaders">Our Leaders</NavItem>
        <NavItem href="/iyf">Youth Forum</NavItem>
        <NavItem href="/#contact">Contact Us</NavItem>
      </div>
      <div
        className={`sidebar-mobile navbar ${
          transparent ? "transparentNav" : ""
        }`}
      >
        <Logo whiteLogo={!transparent} />
        <svg
          width="33"
          height="16"
          viewBox="0 0 33 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={openNav}
          className="openSideBar"
        >
          <rect
            x="0.942383"
            width="32.0578"
            height="2.8"
            rx="1.4"
            fill="white"
          />
          <rect
            x="0.942383"
            y="12.3784"
            width="32.0578"
            height="2.8"
            rx="1.4"
            fill="white"
          />
        </svg>
      </div>
    </>
  );
};

export default Navbar;
