import React from "react";
import HeadingWithCurl from "../HeadingWithCurl/HeadingWithCurl";
import "./NotFound.css";

const NotFound = () => (
  <div className="not-found">
    <HeadingWithCurl smallText="Error 404">Not Found</HeadingWithCurl>
  </div>
);

export default NotFound;
