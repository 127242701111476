import { COLORS, TEXT_ALIGN } from "../../constants";

export const text = {
  smallText1: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0em",
    color: COLORS.white,
    responsive: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "18px",
      letterSpacing: "0em",
      color: COLORS.white,
      marginLeft: "17.5px",
      marginRight: "17.5px",
    },
  },
  hareKrishna: {
    fontFamily: "Syne",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "29px",
    letterSpacing: "0em",
    background: "linear-gradient(90deg, #FF512F, #F09819)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
    textAlign: TEXT_ALIGN.CENTER,
    responsive: {
      fontFamily: "Syne",
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "29px",
      letterSpacing: "0em",
      background: "linear-gradient(90deg, #FF512F, #F09819)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      textFillColor: "transparent",
      textAlign: TEXT_ALIGN.CENTER,
    },
  },
  largeHeading: {
    fontFamily: "Syne",
    fontSize: "48px",
    fontWeight: 700,
    lineHeight: "58px",
    letterSpacing: "0em",
    color: COLORS.darkerBlack,
    responsive: {
      fontFamily: "Syne",
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "normal",
      letterSpacing: "0em",
      color: COLORS.darkerBlack,
    },
  },
  smallText2: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "22px",
    letterSpacing: "0em",
    color: COLORS.darkBlack,
    responsive: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "140%",
      letterSpacing: "0em",
      color: COLORS.darkBlack,
    },
  },
  smallText3: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "22px",
    letterSpacing: "0em",
    color: COLORS.white,
    responsive: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "140%",
      letterSpacing: "0em",
      color: COLORS.white,
    },
  },
  smallText4: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0em",
    color: COLORS.lightBlack,
    responsive: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "normal",
      letterSpacing: "0em",
      color: COLORS.lightBlack,
    },
  },
  smallText5: {
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "22px",
    letterSpacing: "0em",
    color: COLORS.lightBlack,
    responsive: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "normal",
      letterSpacing: "0em",
      color: COLORS.lightBlack,
    },
  },
  smallText6: {
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "22px",
    color: COLORS.mediumBlack,
    responsive: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "normal",
      color: COLORS.mediumBlack,
    },
  },
  buttonText1: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    letterSpacing: "0em",
    responsive: {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "140%",
      letterSpacing: "0em",
    },
  },
  largeHeading2: {
    fontFamily: "Syne",
    fontSize: "48px",
    fontWeight: 700,
    lineHeight: "58px",
    letterSpacing: "0em",
    color: COLORS.white,
    responsive: {
      fontFamily: "Syne",
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "normal",
      letterSpacing: "0em",
      color: COLORS.white,
    },
  },
  smallHeading: {
    fontFamily: "Syne",
    fontSize: "35px",
    fontWeight: 700,
    lineHeight: "42px",
    letterSpacing: "0em",
    color: COLORS.mediumBlack,
    responsive: {
      fontFamily: "Syne",
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "normal",
      letterSpacing: "0em",
      color: COLORS.mediumBlack,
    },
  },
  largeHeading3: {
    fontFamily: "Syne",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "29px",
    letterSpacing: "0em",
    color: COLORS.lightBlack,
    responsive: {
      fontFamily: "Syne",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "normal",
      letterSpacing: "0em",
      color: COLORS.lightBlack,
    },
  },
  heading1: {
    fontFamily: "Syne",
    fontSize: "58px",
    fontWeight: 700,
    lineHeight: "70px",
    letterSpacing: "0em",
    background: "linear-gradient(90deg, #F09819, #EDDE5D)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
    responsive: {
      fontFamily: "Syne",
      fontSize: "38px",
      fontWeight: 700,
      lineHeight: "normal",
      letterSpacing: "0em",
      background: "linear-gradient(90deg, #F09819, #EDDE5D)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      textFillColor: "transparent",
    },
  },
  gradientText: {
    fontFamily: "Syne",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "28px",
    letterSpacing: "0em",
    background: "linear-gradient(90deg, #FF512F 0%, #DD2476 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
    responsive: {
      fontFamily: "Syne",
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "140%",
      letterSpacing: "0em",
      background: "linear-gradient(90deg, #FF512F 0%, #DD2476 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      textFillColor: "transparent",
    },
  },
  greyText: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "0em",
    color: COLORS.darkGrey,
    responsive: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "140%",
      letterSpacing: "0em",
      color: COLORS.darkGrey,
    },
  },
  smallText7: {
    fontFamily: "Syne",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "-0.02em",
    textAlign: "justified",
    color: COLORS.white,
    responsive: {
      fontFamily: "Syne",
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "150%",
      letterSpacing: "0",
      textAlign: "justified",
      color: COLORS.white,
    },
  },
  smallText8: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "21px",
    letterSpacing: "-0.02em",
    textAlign: "justified",
    color: COLORS.white,
    responsive: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "150%",
      letterSpacing: "-0.24px",
      textAlign: "justified",
      color: COLORS.white,
    },
  },
  verySmallText: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "21px",
    letterSpacing: "-0.02em",
    textAlign: "justified",
    color: COLORS.white,
    responsive: {
      fontFamily: "Montserrat",
      fontSize: "11px",
      fontWeight: 500,
      lineHeight: "150%",
      letterSpacing: "-0.22px",
      textAlign: "justified",
      color: COLORS.white,
    },
  },
  cursiveText: {
    fontFamily: "Fasthand",
    fontSize: "21px",
    lineHeight: "34px",
    fontWeight: 400,
  },
  whiteHeading: {
    fontFamily: "Syne",
    fontSize: "35px",
    fontWeight: 700,
    color: COLORS.white,
    responsive: {
      fontFamily: "Syne",
      fontSize: "20px",
      fontWeight: 700,
      color: COLORS.white,
    },
  },
  whiteSmallText: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 500,
    color: COLORS.white,
    responsive: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      color: COLORS.white,
    },
  },
  announcementText: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "150%",
    color: COLORS.white,
    responsive: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "150%",
      color: COLORS.white,
    },
  },
  donationHeading: {
    color: COLORS.white,
    fontFamily: "Syne",
    fontSize: "27px",
    fontWeight: 700,
    responsive: {
      color: COLORS.white,
      fontFamily: "Syne",
      fontSize: "22px",
      fontWeight: 700,
    },
  },
  donationHeading2: {
    color: COLORS.white,
    fontFamily: "Syne",
    fontSize: "24px",
    fontWeight: 700,
    responsive: {
      color: COLORS.white,
      fontFamily: "Syne",
      fontSize: "20px",
      fontWeight: 700,
    },
  },
};

export const button = {
  coloredButton: {
    type: "colored",
  },
  whiteButton: {
    type: "white",
  },
};
