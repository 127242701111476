export const TEXT_ALIGN = {
  LEFT: "left",
  RIGHT: "right",
  CENTER: "center",
};

export const COLORS = {
  darkBlack: "#525252",
  darkerBlack: "#303030",
  mediumBlack: "#373737",
  white: "#FFFFFF",
  lightBlack: "#606060",
  darkGrey: "#4D4D4D",
};

export const API_STATUS = {
  OK: 200,
  OK_CREATED: 201,
};

export const VALDATION_REGEX = {
  name: /^[a-z ,.'-]+$/i,
  mobile: /^([+]\d{2})?\d{10}$/,
  email: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
  city: /^[a-zA-Z ]*$/,
  pinCode: /^[1-9][0-9]{5}$/,
};
